import { Injectable, NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import { UserService } from './user.service';
import { User as UserAnybuddy } from '../models/user';
import { User } from '../models/user-firebase';
import { TagManagerService } from './tag-manager.service';

import {
  GoogleAuthProvider,
  FacebookAuthProvider,
  OAuthProvider,
  createUserWithEmailAndPassword,
  Auth,
  signInWithEmailAndPassword,
  authState,
  sendEmailVerification,
  sendPasswordResetEmail,
  signOut,
  applyActionCode,
  confirmPasswordReset,
  verifyPasswordResetCode,
  signInWithCustomToken,
  getRedirectResult,
  useDeviceLanguage,
  sendSignInLinkToEmail,
  reauthenticateWithCredential,
  updateEmail,
  reauthenticateWithPopup,
  RecaptchaVerifier,
  PhoneAuthProvider,
  updatePhoneNumber,
  signInWithPopup
} from '@angular/fire/auth';
import { LocalStorageService } from '../../shared/services/local-storage.service';

const actionCodeSettings = {
  dynamicLinkDomain: environment.firebase.dynamicLinkDomain,
  // URL you want to redirect back to. The domain (www.example.com) for this
  // URL must be in the authorized domains list in the Firebase Console.
  url: `${environment.host}/complete-sign-up`,
  // This must be true.
  handleCodeInApp: true,
  iOS: {
    bundleId: 'com.anybuddyapp.anybuddy'
  },
  android: {
    packageName: 'com.anybuddyapp.anybuddy',
    installApp: environment.installApp,
    minimumVersion: '12'
  }
};

@Injectable({
  providedIn: 'root'
})
export class AuthFirebaseService {
  userData: UserAnybuddy; // Save logged in user data
  userDataFire: User; // Save logged in user data
  token: string; // Save token
  isLoggedIn = false; // Save token
  isTokenGetted = new BehaviorSubject(false);
  sendLoginManager = false;
  isNewUser = false;
  userId = '';
  constructor(
    public auth: Auth,
    public router: Router,
    public route: ActivatedRoute,
    public ngZone: NgZone, // NgZone service to remove outside scope warning
    private userService: UserService,
    private tagManager: TagManagerService,
    private storageService: LocalStorageService
  ) {
    useDeviceLanguage(this.auth);
    this.initDataToken();
  }
  getSendLoginManager() {
    const sendLoginManager = this.storageService.getItem('sendLoginManager');
    if (sendLoginManager != null && sendLoginManager === 'true') {
      this.sendLoginManager = true;
      this.storageService.removeItem('sendLoginManager');
    }
  }

  getUserDataId(): string {
    if (this.userId || this.userData) {
      return this.userId ? this.userId : this.userData.id;
    }
    return 'unknown';
  }

  getAuthState() {
    return authState(this.auth);
  }

  initDataToken(): void {
    this.storageService.setItem('user', 'null');
    authState(this.auth).subscribe((user) => {
      if (user) {
        this.userId = user.uid;
        this.userDataFire = user;
        this.isNewUser = this._isNewUser();

        user
          .getIdToken()
          .then((token) => {
            if (token) {
              if (
                this.router.url.includes('/login') ||
                this.router.url.includes('/sign-up')
              ) {
                const redirectUrl =
                  this.route.snapshot.queryParamMap.get('redirectUrl');
                if (redirectUrl) {
                  this.router.navigateByUrl(redirectUrl);
                } else {
                  this.router.navigate(['/account']);
                }
              }
              this.isLoggedIn = true;
              this.token = token;
              this.isTokenGetted.next(true);
              this.getUserData();
            } else {
              this.isLoggedIn = false;
              this.token = '';
              this.isTokenGetted.next(true);
            }
          })
          .catch(() => {
            this.isLoggedIn = false;
            this.token = '';
            this.isTokenGetted.next(true);
          });
      }
    });
  }

  _isNewUser(): boolean {
    const metadata = this.auth.currentUser.metadata;
    return metadata.creationTime == metadata.lastSignInTime ? true : false;
  }

  getUserData() {
    this.getUserBuddy().subscribe((user) => {
      this.userData = user;
      this.getSendLoginManager();
      if (this.sendLoginManager) {
        this.sendLoginManager = false;
        if (!this.userId) {
          this.userId = this.userData.id;
        }
        if (this.isNewUser) {
          this.tagManager.pushRegister(
            this.userId,
            this.userDataFire.displayName,
            this.userDataFire.email
          );
        }
        this.tagManager.pushIdentify(
          this.userId,
          this.userDataFire.displayName,
          this.userDataFire.email
        );
        this.tagManager.pushLogin(this.userId);
      }
    });
  }

  getUserBuddy() {
    return this.userService.getMe();
  }

  SignInWithCustomToken(token: string) {
    return signInWithCustomToken(this.auth, token);
  }

  getUser(): User {
    return this.auth.currentUser;
  }

  getUserFire() {
    return this.auth.currentUser;
  }

  reloadUser(): Promise<void> {
    return this.auth.currentUser.reload();
  }

  // Returns true when user is looged in and email is verified
  get getIsLoggedIn(): boolean {
    return this.token != null && this.token != '';
  }

  _signUp(email: string, password: string) {
    this.auth.tenantId = environment.firebase.tenantId;
    return createUserWithEmailAndPassword(this.auth, email, password)
      .then((result) => {
        this._sendVerificationMail();
        this.userDataFire = result.user;
        return Promise.resolve(result.user);
      })
      .catch((error) => {
        return Promise.reject(error.message);
      });
  }

  _signIn(email: string, password: string) {
    this.auth.tenantId = environment.firebase.tenantId;
    return signInWithEmailAndPassword(this.auth, email, password)
      .then((result) => {
        this.userDataFire = result.user;
        authState(this.auth).subscribe((user) => {
          if (user) {
            Promise.resolve(user);
          } else {
            Promise.reject('No user');
          }
        });
      })
      .catch((error) => {
        return Promise.reject(error.message);
      });
  }

  _sendVerificationMail() {
    if (this.auth.currentUser) {
      this.auth.tenantId = environment.firebase.tenantId;
      return sendEmailVerification(this.auth.currentUser);
    }
    return Promise.reject('No user');
  }

  _forgotPassword(resetMail: string) {
    console.log('resetMail', resetMail);
    this.auth.tenantId = environment.firebase.tenantId;
    return sendPasswordResetEmail(this.auth, resetMail)
      .then(() => {
        console.log('Password reset email sent, check your inbox.');
      })
      .catch((error) => {
        console.error('_forgotPassword', error);
      });
  }

  _signinWithProvider(provider) {
    localStorage.setItem('isAuthentifiying', 'true');
    this.auth.tenantId = environment.firebase.tenantId;
    return signInWithPopup(this.auth, provider);
  }

  _googleAuth() {
    return this._signinWithProvider(new GoogleAuthProvider());
  }

  _facebookAuth() {
    return this._signinWithProvider(new FacebookAuthProvider());
  }

  _appleAuth() {
    return this._signinWithProvider(new OAuthProvider('apple.com'));
  }

  _signOut() {
    this.token = '';
    this.isLoggedIn = false;
    this.userData = null;
    localStorage.setItem('user', 'null');
    this.auth.tenantId = environment.firebase.tenantId;
    return signOut(this.auth).then(
      () => {
        console.log('disconnected');
      },
      (err) => {
        console.error('_signOut', err);
      }
    );
  }

  _verifyPasswordResetCode(code) {
    this.auth.tenantId = environment.firebase.tenantId;
    return verifyPasswordResetCode(this.auth, code);
  }

  _confirmPasswordReset(code: string, password: string) {
    this.auth.tenantId = environment.firebase.tenantId;
    return confirmPasswordReset(this.auth, code, password);
  }

  _applyActionCode(code: string) {
    return applyActionCode(this.auth, code);
  }

  _signInWithCustomToken(token: string) {
    this.auth.tenantId = environment.firebase.tenantId;
    return signInWithCustomToken(this.auth, token);
  }

  _signUpWithMail(email: string) {
    return sendSignInLinkToEmail(this.auth, email, actionCodeSettings);
  }

  _getRedirectResult() {
    return getRedirectResult(this.auth);
  }

  _reauthenticateWithCredential(credential) {
    return reauthenticateWithCredential(this.auth.currentUser, credential);
  }

  _updateEmail(email: string) {
    return updateEmail(this.auth.currentUser, email);
  }

  _reauthenticateWithPopup(provider) {
    return reauthenticateWithPopup(this.auth.currentUser, provider);
  }

  _verifyPhoneNumber(
    recaptchaContainer,
    recaptchaParameters,
    phoneNumber: string
  ) {
    const appVerifier = new RecaptchaVerifier(
      recaptchaContainer,
      recaptchaParameters,
      this.auth
    );
    const provider = new PhoneAuthProvider(this.auth);
    return provider.verifyPhoneNumber(phoneNumber, appVerifier);
  }

  _getPhoneAuthCredential(verificationId: string, verificationCode: string) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(PhoneAuthProvider.credential(verificationId, verificationCode));
      }, 10);
    });
  }

  _updatePhoneNumber(credential) {
    return updatePhoneNumber(this.auth.currentUser, credential);
  }
}
